@import "fonts";
@tailwind base;
@tailwind components;
@tailwind utilities;
@import "grid-system";
@import "components/configurator";
@import "components/drawer";
@import "components/liveplayer";
@import "components/mediathek";
@import "components/price";
@import "tailwind-prose";

body {
  font-family: Avenir;
}

.prose {
  ul {
    list-style: disc;
    margin: initial;
    margin-left: 20px;
    padding: initial;
  }

  p > a {
    color: #00537e;
    position: relative;
    text-decoration: none;

    &:hover {
      color: #00537e;
    }

    &::before {
      content: "";
      position: absolute;
      width: 100%;
      height: 1px;
      bottom: 0;
      left: 0;
      background-color: #00537e;
      visibility: hidden;
      transform: scaleX(0);
      transition: all 0.3s ease-in-out 0s;
    }

    &:hover::before {
      visibility: visible;
      transform: scaleX(1);
    }
  }
}

.react-player {
  position: absolute;
  top: 0;
  left: 0;
}

.react-player-wrapper {
  position: relative;
  padding-top: 56.25%; /* 720 / 1280 = 0.5625 */
}

.be__tilecontainer .pimcore_block_entry {
  @apply bg-white shadow-lg flex flex-col m-4 overflow-hidden px-5 py-2 text-center;

  margin-top: 2em;
}

.be__input--width {
  width: 200px;
}

.news__image--height {
  height: 300px;
}

.hero-split__image--height {
  height: 400px;
}

.hero-split__body--height {
  height: 300px;
}

.hero-gradiant--height {
  height: auto;
}

@screen lg {
  .news__image--height {
    height: 400px;
  }

  .hero-split__image--height {
    height: 515px;
  }

  .hero-split__body--height {
    height: 515px;
  }

  .hero-gradiant--height {
    height: 400px;
  }
}

.hero__background--gradient {
  background: rgb(217, 226, 231);
  background: radial-gradient(circle, rgba(217, 226, 231, 100%) 0%, rgba(147, 174, 188, 100%) 100%);
}

.leading-1-25 {
  line-height: 1.25 !important;
}

.hero-simple__image--darken {
  filter: brightness(50%);
}

@layer utilities {
  .text-shadow {
    text-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  }

  .text-shadow-md {
    text-shadow: 0 4px 8px rgba(0, 0, 0, 0.22), 0 2px 4px rgba(0, 0, 0, 0.18);
  }

  .text-shadow-lg {
    text-shadow: 0 15px 30px rgba(0, 0, 0, 0.21), 0 5px 15px rgba(0, 0, 0, 0.18);
  }

  .text-shadow-none {
    text-shadow: none;
  }

  .text-shadow-white {
    text-shadow: 0 2px 4px rgba(255, 255, 255, 0.8);
  }

  .text-shadow-sm-white {
    text-shadow: 0 1px 2px rgba(255, 255, 255, 0.85);
  }

  .text-shadow-md-white {
    text-shadow: 0 4px 8px rgba(255, 255, 255, 0.78), 0 2px 4px rgba(255, 255, 255, 0.72);
  }

  .text-shadow-lg-white {
    text-shadow: 0 15px 30px rgba(255, 255, 255, 0.81), 0 5px 15px rgba(255, 255, 255, 0.72);
  }
}

.react-pdf__Page__canvas {
  margin: 0 auto;
}

.headline__underline {
  &::after {
    content: "";
    display: block;
    width: 25%;
    padding-top: 10px;
    border-bottom: 3px solid black;
  }

  &--primary::after {
    border-bottom-color: theme("colors.brand.primary");
  }

  &--secondary::after {
    border-bottom-color: theme("colors.brand.secondary");
  }

  &--white::after {
    border-bottom-color: white;
  }

  &--lightBlue::after {
    border-bottom-color: #40eaff;
  }

  &--blue::after {
    border-bottom-color: #00537e;
  }

  &--blue1::after {
    border-bottom-color: #004360;
  }

  &--blue2::after {
    border-bottom-color: #087399;
  }

  &--blue3::after {
    border-bottom-color: #25b4cc;
  }

  &--blue4::after {
    border-bottom-color: #06ed6b;
  }

  &--lightblue0::after {
    border-bottom-color: #00dbff;
  }

  &--lightblue1::after {
    border-bottom-color: #38bad9;
  }

  &--lightblue2::after {
    border-bottom-color: #40eaff;
  }

  &--lightblue3::after {
    border-bottom-color: #73ffff;
  }

  &--lightblue4::after {
    border-bottom-color: #a6fffd;
  }

  &--red0::after {
    border-bottom-color: #fe0000;
  }

  &--red1::after {
    border-bottom-color: #cb100a;
  }

  &--red2::after {
    border-bottom-color: #f93f43;
  }

  &--red3::after {
    border-bottom-color: #f77375;
  }

  &--red4::after {
    border-bottom-color: #f6a6a9;
  }

  &--yellow0::after {
    border-bottom-color: #ffff1a;
  }

  &--yellow1::after {
    border-bottom-color: #efe300;
  }

  &--yellow2::after {
    border-bottom-color: #fff35c;
  }

  &--yellow3::after {
    border-bottom-color: #fff48d;
  }

  &--yellow4::after {
    border-bottom-color: #fff7c0;
  }

  &--green0::after {
    border-bottom-color: #3f3;
  }

  &--green1::after {
    border-bottom-color: #32cc32;
  }

  &--green2::after {
    border-bottom-color: #6f6;
  }

  &--green3::after {
    border-bottom-color: #9f9;
  }

  &--green4::after {
    border-bottom-color: #cfc;
  }

  &--purple1::after {
    border-bottom-color: #d29ad4;
  }

  &--purple2::after {
    border-bottom-color: #ad60af;
  }

  &--purple3::after {
    border-bottom-color: #a742a3;
  }

  &--bluegreen1::after {
    border-bottom-color: #418885;
  }

  &--bluegreen2::after {
    border-bottom-color: #017b76;
  }

  &--lightbluevariant1::after {
    border-bottom-color: #6bf2e4;
  }

  &--lightbluevariant2::after {
    border-bottom-color: #51decf;
  }

  &--thick::after {
    border-bottom-width: 5px;
  }

  &--medium::after {
    border-bottom-width: 4px;
  }

  &--10::after {
    width: 10%;
  }

  &--15::after {
    width: 15%;

    @media screen and (max-width: 720px) {
      width: 50%;
    }
  }

  &--20::after {
    width: 20%;

    @media screen and (max-width: 720px) {
      width: 50%;
    }
  }

  &--30::after {
    width: 30%;

    @media screen and (max-width: 720px) {
      width: 50%;
    }
  }

  &--35::after {
    width: 35%;

    @media screen and (max-width: 720px) {
      width: 50%;
    }
  }

  &--right::after {
    margin: 0 0 0 auto;
  }

  &--center::after {
    margin: 0 auto;
  }
}

ul.checkbox > li::before {
  background-image: url("data:image/svg+xml;utf8,<span style='color:green'><svg xmlns='http://www.w3.org/2000/svg' class='w-16 h-16' fill='none' viewBox='0 0 24 24' stroke='currentColor'><path stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m5 13 4 4L19 7'/></svg></span>");
  background-size: 90%;
  background-position: center;
  transition: background-size 0.3s;
  -webkit-transition: background-size 0.3s;
}

ul.custom-bullet {
  list-style: none;
}

ul.custom-bullet > li > li {
  clear: left;
}

ul.custom-bullet > li::before > li::before {
  content: "";
  height: 1em;
  width: 1em;
  display: block;
  float: left;
  margin-left: -1.5em;
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100%;
}

.accordion__item__body {
  max-height: 500px;
}

.accordion__item__body[invisible=""] {
  max-height: 0;
}

@tailwind variants;
