.sitzkrieger-collection__liveplayer {
  border-width: 0;
  border-style: solid;
  border-color: rgb(226, 232, 240);
  color: rgb(26, 32, 44);
  line-height: 1.5;
  text-align: center;
}

.sitzkrieger-header__countdown {
  // declaration only
}

.sitzkrieger-collection__liveplayer__countdown {
  width: 100%;
  margin: 35px auto;
}

.sitzkrieger-collection__liveplayer__countdown__wrapper {
  margin-top: 1.25rem;
  margin-bottom: 1.25rem;
}

.sitzkrieger-collection__liveplayer__countdown__headline {
  font-size: 36px;
  line-height: 1.25;
  font-weight: 700;
  margin-bottom: 0.5rem;
  text-align: center;

  @media screen and (min-width: 48em) {
    font-size: 48px;
  }
}

.sitzkrieger-collection__liveplayer__countdown__headline--subtitle {
  font-size: 16px;
  margin-bottom: 1.25rem;

  @media screen and (min-width: 48em) {
    font-size: 16px;
  }
}

.sitzkrieger-collection__liveplayer__countdown__subtext {
  margin: 0 auto;
  text-align: center;

  @media screen and (min-width: 48em) {
    max-width: 50%;
  }
}

.sitzkrieger-collection__liveplayer__countdown__body {
  display: flex;
  align-items: flex-start;
  justify-content: space-around;
  flex-wrap: wrap;
  margin: 0 auto;
  max-width: 50%;
}

.sitzkrieger-header__countdown .sitzkrieger-collection__liveplayer__countdown__body {
  justify-content: unset;
  margin: 0;
  max-width: 100%;
  flex-wrap: unset;
}

.sitzkrieger-collection__liveplayer__countdown__body__tile {
  @apply rounded-xl;

  flex: 1 1 0;
  position: relative;
  margin: 3px;
  width: 150px;
  box-shadow: rgba(0, 0, 0, 0.1) 0 10px 15px -3px, rgba(0, 0, 0, 0.05) 0 4px 6px -2px;
  padding: 0.5em;

  p {
    margin: 0;
    font-size: 32px;
    vertical-align: baseline;
    font-weight: 600;

    @media screen and (min-width: 30em) {
      font-size: 56px;
    }
  }
}

.sitzkrieger-header__countdown .sitzkrieger-collection__liveplayer__countdown__body__tile {
  margin: 2px;
  width: 70px;

  p {
    padding-top: 7px !important;
    padding-bottom: 5px !important;
    font-size: 32px !important;
    text-align: center;

    @media screen and (min-width: 520px) {
      padding-top: 15px !important;
      padding-bottom: 10px !important;
      font-size: 42px !important;
    }

    @media screen and (min-width: 730px) {
      font-size: 70px !important;
      padding-top: 30px !important;
      padding-bottom: 20px !important;
    }
  }

  @media screen and (min-width: 520px) {
    margin: 8px;
    width: 100px;
  }

  @media screen and (min-width: 730px) {
    width: 150px;
  }
}

.sitzkrieger-collection__liveplayer__video {
  width: 100%;
}

.react-player-wrapper {
  position: relative;
  padding-top: 56.25%;
}

.react-player {
  position: absolute;
  top: 0;
  left: 0;
}

.sitzkrieger-collection__liveplayer__subscribe {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  width: 100%;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
}

.sitzkrieger-collection__liveplayer__subscribe__avatar {
  font-size: calc(1.6rem);
  line-height: 4rem;
  vertical-align: top;
  display: inline-flex;
  border-radius: 9999px;
  -webkit-box-align: center;
  align-items: center;
  flex-shrink: 0;
  -webkit-box-pack: center;
  justify-content: center;
  position: relative;
  width: 4rem;
  height: 4rem;
  background-color: initial;
  color: rgb(26, 32, 44);
  margin-left: 1.25rem;
  margin-right: 1.25rem;

  .sitzkrieger-collection__liveplayer__subscribe__avatar__image {
    width: 100%;
    height: 100%;
    border-radius: 9999px;
    object-fit: cover;
    max-width: 100%;
  }
}

.sitzkrieger-collection__liveplayer__subscribe_body {
  margin-left: 0.75rem;
  width: 100%;
  text-align: left;

  a {
    background-color: transparent;
    color: black;
  }

  .sitzkrieger-collection__liveplayer__subscribe_text {
    margin: 0;

    & > a {
      position: relative;
      color: black;

      &:hover {
        color: #00537e;
        text-decoration: underline;
      }
    }
  }
}

.sitzkrieger-collection__liveplayer__subscribe__body__link {
  transition: all 0.15s ease-out 0s;
  cursor: pointer;
  text-decoration: none;
  outline: none;
  font-weight: 600;
  color: black;

  .sitzkrieger-collection__liveplayer__subscribe__body__link--small {
    font-weight: initial;
  }
}

.sitzkrieger-collection__liveplayer__badge {
  display: inline-block;
  padding: 0.25rem;
  text-transform: uppercase;
  font-size: 3rem;
  border-radius: 0.125rem;
  font-weight: 700;
  white-space: nowrap;
  vertical-align: middle;
  box-shadow: rgb(229, 62, 62) 0 0 0 1px inset;
  color: rgb(229, 62, 62);
  margin-right: 0.5rem;
  margin-bottom: 7px;

  &--small {
    font-size: 28px;
    margin-bottom: 7px;
  }

  @media screen and (min-width: 730px) {
    padding-top: 15px;
    padding-bottom: 15px;
  }
}

.sitzkrieger-collection__liveplayer__loading {
  display: flex;
  justify-content: center;

  .sitzkrieger-collection__liveplayer__loading__svg {
    width: 100px;
    height: 100px;
    color: #00537e;
    animation-name: spin;
    animation-duration: 5000ms;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
  }
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}
