@font-face {
  font-family: system;
  font-style: normal;
  font-weight: 300;
  src:
    local(".SFNSText-Light"),
    local(".HelveticaNeueDeskInterface-Light"),
    local(".LucidaGrandeUI"),
    local("Ubuntu Light"),
    local("Segoe UI Light"),
    local("Roboto-Light"),
    local("DroidSans"),
    local("Tahoma");
}

@font-face {
  font-family: Avenir;
  src: local("system"), url("../Fonts/AvenirLTStd-Medium.otf");
  font-display: swap;
  font-weight: 500;
}

@font-face {
  font-family: Avenir;
  src: local("system"), url("../Fonts/AvenirLTStd-Black.otf");
  font-display: swap;
  font-weight: 900;
}

@font-face {
  font-family: Avenir;
  src: local("system"), url("../Fonts/AvenirLTStd-Light.otf");
  font-weight: 300;
}

@font-face {
  font-family: Avenir;
  src: local("system"), url("../Fonts/AvenirLTStd-Heavy.otf");
  font-display: swap;
  font-weight: 700;
}

@font-face {
  font-family: "Source Sans Pro";
  src: local("system"), url("../Fonts/Source_Sans_Pro/SourceSansPro-Regular.ttf");
  font-display: swap;
  font-weight: 500;
}

@font-face {
  font-family: "Source Sans Pro";
  src: local("system"), url("../Fonts/Source_Sans_Pro/SourceSansPro-Black.ttf");
  font-display: swap;
  font-weight: 900;
}

@font-face {
  font-family: "Source Sans Pro";
  src: local("system"), url("../Fonts/Source_Sans_Pro/SourceSansPro-Light.ttf");
  font-weight: 300;
}

@font-face {
  font-family: "Source Sans Pro";
  src: local("system"), url("../Fonts/Source_Sans_Pro/SourceSansPro-Bold.ttf");
  font-display: swap;
  font-weight: 700;
}
