.simpleGrid {
  display: -ms-grid;
  -ms-grid-columns: 1fr;

  @media screen and (min-width: 62em) {
    -ms-grid-columns: 1fr 1fr;
  }
}

.simpleGrid--firstChild {
  -ms-grid-column: 1;
  -ms-grid-row: 1;
}

.simpleGrid--secondChild {
  -ms-grid-column: 1;
  -ms-grid-row: 2;

  @media screen and (min-width: 62em) {
    -ms-grid-column: 2;
    -ms-grid-row: 1;
  }
}

.simpleGrid--thirdChild {
  -ms-grid-column: 1;
  -ms-grid-row: 3;

  @media screen and (min-width: 62em) {
    -ms-grid-column: 3;
    -ms-grid-row: 1;
  }
}

.simpleGrid--centerRow {
  -ms-grid-row-align: center;
}

.simpleGrid--centerColumn {
  -ms-grid-column-align: center;
}

.simpleGrid__landingPage__hero {
  @media all and (-ms-high-contrast: none) {
    padding-top: 50% !important;

    h3 {
      max-width: 90% !important;
    }
  }
}

.simpleGrid__threeCol {
  @media all and (-ms-high-contrast: none) {
    display: -ms-flexbox;
    -ms-flex-direction: row;
    -ms-flex-wrap: wrap;

    div {
      flex: 1 0 auto;
    }
  }
}

.simpleGrid__threeCol--block {
  @media all and (-ms-high-contrast: none) {
    display: block;
    width: 100%;

    div {
      float: left;
    }
  }
}
