.sitzkrieger-collection__modal {
  outline: 0;
  max-width: 650px;
  padding: initial;
  min-height: 350px;
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  z-index: 1400;
  background-color: rgb(255, 255, 255);
  box-shadow: rgba(0, 0, 0, 10%) 0 7px 14px 0, rgba(0, 0, 0, 7%) 0 3px 6px 0;
  top: 0;
  color: rgb(26, 32, 44);

  h3 {
    font-size: 20px;
    line-height: 1.25;
    font-weight: 700;
    font-family: Avenir, system, sans-serif;
    margin: 0 0 1.25rem;
    text-align: center;
    color: rgb(26, 32, 44);
  }

  .sitzkrieger-collection__header {
    padding: 2rem 1.5rem;
    position: relative;
    font-size: 28px;
    font-weight: 600;
    text-align: center;
    color: white;
  }

  .sitzkrieger-collection__body {
    padding: 1.5rem;
    flex: 1 1 0;

    .sitzkrieger-collection__body__content {
      display: flex;
      flex-flow: row wrap;
      justify-content: space-evenly;

      p {
        text-align: justify;
        margin-top: 1rem;
        margin-bottom: 1rem;
        font-size: 16px;
      }
    }
  }

  .sitzkrieger-collection__footer {
    display: flex;
    -webkit-box-pack: end;
    justify-content: flex-end;
    padding: 1rem 1.5rem;
  }
}

.sitzkrieger-collection__button--close {
  outline: none;
  width: 32px;
  height: 32px;
  display: inline-flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  border-radius: 0.25rem;
  transition: all 0.2s ease 0s;
  flex: 0 0 auto;
  position: absolute;
  top: 8px;
  right: 12px;

  svg {
    max-width: initial;
    width: 12px;
    height: 12px;
    color: white;
    display: inline-block;
    vertical-align: middle;
    flex-shrink: 0;
    backface-visibility: hidden;
  }
}

.sitzkrieger-collection__button {
  @apply rounded-full;

  font-weight: 600;
  display: inline-flex;
  appearance: none;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  transition: all 250ms ease 0s;
  user-select: none;
  position: relative;
  white-space: nowrap;
  vertical-align: middle;
  line-height: 1.2;
  outline: none;
  height: 40px;
  min-width: 2.5rem;
  font-size: 16px;
  padding-left: 1rem;
  padding-right: 1rem;
  background: rgb(237, 242, 247);
  color: black;
  cursor: pointer;
  text-transform: none;
}

.sitzkrieger-collection__button--blue {
  @apply bg-brand-secondary;

  height: 3rem;
  min-width: 3rem;
  font-size: 18px;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  color: rgb(255, 255, 255);
  margin: 0 auto;
}

.sitzkrieger-collection__button--backButton {
  @apply bg-brand-secondary;

  min-width: 3rem;
  font-size: 15px;
  color: rgb(255, 255, 255);
}

.sitzkrieger-collection__button--withSpace {
  min-width: 40%;
  margin: 1.25rem;
}

.sitzkrieger-collection__flex--center {
  display: flex;
  width: 100%;
  justify-content: center;
}

.sitzkrieger-collection__tabs__list {
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: start;
  justify-content: flex-start;
  max-width: 100%;
}

.sitzkrieger-collection__tabs__tabButton {
  outline: none;
  display: flex;
  cursor: pointer;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  transition: all 0.2s ease 0s;
  font-size: 1rem;
  padding: 1px;
  height: 100%;
  margin: 0.5rem;
  position: relative;

  &--fixedHeight {
    height: 75px;

    img {
      height: 100% !important;
    }
  }
}

.sitzkrieger-collection__tabs__tabButton--selected {
  border: 1px solid #0a246a;
}

.sitzkrieger-collection__tabs__image {
  width: 50px;
  height: 35px;
}
