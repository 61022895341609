.drawer__container {
  outline: currentcolor none 0;
  max-width: 20rem;
  width: 100%;
  position: fixed;
  flex-direction: column;
  z-index: 1400;
  background-color: rgb(255, 255, 255);
  box-shadow: rgba(0, 0, 0, 10%) 0 7px 14px 0, rgba(0, 0, 0, 7%) 0 3px 6px 0;
  height: 100vh;
  right: 0;
  top: 0;

  .drawer__button--close {
    outline: currentcolor none medium;
    width: 1.5rem;
    height: 1.5rem;
    display: inline-flex;
    -moz-box-align: center;
    align-items: center;
    -moz-box-pack: center;
    justify-content: center;
    border-radius: 0.25rem;
    transition: all 0.2s ease 0s;
    flex: 0 0 auto;
    position: fixed;
    top: 16px;
    right: 12px;
    z-index: 1;
  }

  .drawer__header {
    padding: 1rem 1.5rem;
    position: relative;
    font-size: 20px;
    font-weight: 600;
  }

  .drawer__body {
    padding: 0.5rem 1.5rem;
    flex: 1 1 0%;
  }

  .drawer__link__icons--blue {
    width: 1em;
    height: 1em;
    color: rgb(0, 83, 126);
    display: inline-block;
    vertical-align: middle;
    margin-right: 0.5rem;
    flex-shrink: 0;
    backface-visibility: hidden;
  }
}
