.tw-prose {
  color: inherit;
}

.tw-prose :where([class~="lead"]):not(:where([class~="not-prose"] *)) {
  color: var(--tw-prose-lead);
  font-size: 1.25em;
  line-height: 1.6;
  margin-top: 1.2em;
  margin-bottom: 1.2em;
}

.tw-prose :where(a):not(:where([class~="not-prose"] *)) {
  color: var(--tw-prose-links);
  text-decoration: underline;
  font-weight: 500;
}

.tw-prose :where(strong):not(:where([class~="not-prose"] *)) {
  color: var(--tw-prose-bold);
  font-weight: 600;
}

.tw-prose :where(ol):not(:where([class~="not-prose"] *)) {
  list-style-type: decimal;
  padding-left: 1.625em;
}

.tw-prose :where(ol[type="A"]):not(:where([class~="not-prose"] *)) {
  list-style-type: upper-alpha;
}

.tw-prose :where(ol[type="a"]):not(:where([class~="not-prose"] *)) {
  list-style-type: lower-alpha;
}

.tw-prose :where(ol[type="I"]):not(:where([class~="not-prose"] *)) {
  list-style-type: upper-roman;
}

.tw-prose :where(ol[type="i"]):not(:where([class~="not-prose"] *)) {
  list-style-type: lower-roman;
}

.tw-prose :where(ol[type="1"]):not(:where([class~="not-prose"] *)) {
  list-style-type: decimal;
}

.tw-prose :where(ul):not(:where([class~="not-prose"] *)) {
  list-style-type: disc;
  padding-left: 1.625em;
}

.tw-prose :where(ol > li):not(:where([class~="not-prose"] *))::marker {
  font-weight: 400;
  color: var(--tw-prose-counters);
}

.tw-prose :where(ul > li):not(:where([class~="not-prose"] *))::marker {
  color: var(--tw-prose-bullets);
}

.tw-prose :where(hr):not(:where([class~="not-prose"] *)) {
  border-color: var(--tw-prose-hr);
  border-top-width: 1px;
  margin-top: 3em;
  margin-bottom: 3em;
}

.tw-prose :where(blockquote p:first-of-type):not(:where([class~="not-prose"] *))::before {
  content: open-quote;
}

.tw-prose :where(blockquote p:last-of-type):not(:where([class~="not-prose"] *))::after {
  content: close-quote;
}

.tw-prose :where(h1):not(:where([class~="not-prose"] *)) {
  color: var(--tw-prose-headings);
  font-weight: 800;
  font-size: 2.25em;
  margin-top: 0;
  margin-bottom: 0.8888889em;
  line-height: 1.1111111;
}

.tw-prose :where(h1 strong):not(:where([class~="not-prose"] *)) {
  font-weight: 900;
}

.tw-prose :where(h2):not(:where([class~="not-prose"] *)) {
  color: var(--tw-prose-headings);
  font-weight: 700;
  font-size: 1.5em;
  margin-top: 2em;
  margin-bottom: 1em;
  line-height: 1.3333333;
}

.tw-prose :where(h2 strong):not(:where([class~="not-prose"] *)) {
  font-weight: 800;
}

.tw-prose :where(h3):not(:where([class~="not-prose"] *)) {
  color: var(--tw-prose-headings);
  font-weight: 600;
  font-size: 1.25em;
  margin-top: 1.6em;
  margin-bottom: 0.6em;
  line-height: 1.6;
}

.tw-prose :where(h3 strong):not(:where([class~="not-prose"] *)) {
  font-weight: 700;
}

.tw-prose :where(h4):not(:where([class~="not-prose"] *)) {
  color: var(--tw-prose-headings);
  font-weight: 600;
  margin-top: 1.5em;
  margin-bottom: 0.5em;
  line-height: 1.5;
}

.tw-prose :where(h4 strong):not(:where([class~="not-prose"] *)) {
  font-weight: 700;
}

.tw-prose :where(figure > *):not(:where([class~="not-prose"] *)) {
  margin-top: 0;
  margin-bottom: 0;
}

.tw-prose :where(figcaption):not(:where([class~="not-prose"] *)) {
  color: var(--tw-prose-captions);
  font-size: 0.875em;
  line-height: 1.4285714;
  margin-top: 0.8571429em;
}

.tw-prose :where(code):not(:where([class~="not-prose"] *)) {
  color: var(--tw-prose-code);
  font-weight: 600;
  font-size: 0.875em;
}

.tw-prose :where(code):not(:where([class~="not-prose"] *))::before {
  content: "`";
}

.tw-prose :where(code):not(:where([class~="not-prose"] *))::after {
  content: "`";
}

.tw-prose :where(a code):not(:where([class~="not-prose"] *)) {
  color: var(--tw-prose-links);
}

.tw-prose :where(pre):not(:where([class~="not-prose"] *)) {
  color: var(--tw-prose-pre-code);
  background-color: var(--tw-prose-pre-bg);
  overflow-x: auto;
  font-weight: 400;
  font-size: 0.875em;
  line-height: 1.7142857;
  margin-top: 1.7142857em;
  margin-bottom: 1.7142857em;
  border-radius: 0.375rem;
  padding-top: 0.8571429em;
  padding-right: 1.1428571em;
  padding-bottom: 0.8571429em;
  padding-left: 1.1428571em;
}

.tw-prose :where(pre code):not(:where([class~="not-prose"] *)) {
  background-color: transparent;
  border-width: 0;
  border-radius: 0;
  padding: 0;
  font-weight: inherit;
  color: inherit;
  font-size: inherit;
  font-family: inherit;
  line-height: inherit;
}

.tw-prose :where(pre code):not(:where([class~="not-prose"] *))::before {
  content: none;
}

.tw-prose :where(pre code):not(:where([class~="not-prose"] *))::after {
  content: none;
}

.tw-prose :where(table):not(:where([class~="not-prose"] *)) {
  width: 100%;
  table-layout: auto;
  text-align: left;
  margin-top: 2em;
  margin-bottom: 2em;
  font-size: 0.875em;
  line-height: 1.7142857;
}

.tw-prose :where(thead):not(:where([class~="not-prose"] *)) {
  border-bottom-width: 1px;
  border-bottom-color: var(--tw-prose-th-borders);
}

.tw-prose :where(thead th):not(:where([class~="not-prose"] *)) {
  color: var(--tw-prose-headings);
  font-weight: 600;
  vertical-align: bottom;
  padding-right: 0.5714286em;
  padding-bottom: 0.5714286em;
  padding-left: 0.5714286em;
}

.tw-prose :where(tbody tr):not(:where([class~="not-prose"] *)) {
  border-bottom-width: 1px;
  border-bottom-color: var(--tw-prose-td-borders);
}

.tw-prose :where(tbody tr:last-child):not(:where([class~="not-prose"] *)) {
  border-bottom-width: 0;
}

.tw-prose :where(tbody td):not(:where([class~="not-prose"] *)) {
  vertical-align: baseline;
  padding-top: 0.5714286em;
  padding-right: 0.5714286em;
  padding-bottom: 0.5714286em;
  padding-left: 0.5714286em;
}

.tw-prose {
  --tw-prose-body: #374151;
  --tw-prose-headings: #111827;
  --tw-prose-lead: #4b5563;
  --tw-prose-links: #111827;
  --tw-prose-bold: #111827;
  --tw-prose-counters: #6b7280;
  --tw-prose-bullets: #d1d5db;
  --tw-prose-hr: #e5e7eb;
  --tw-prose-quotes: #111827;
  --tw-prose-quote-borders: #e5e7eb;
  --tw-prose-captions: #6b7280;
  --tw-prose-code: #111827;
  --tw-prose-pre-code: #e5e7eb;
  --tw-prose-pre-bg: #1f2937;
  --tw-prose-th-borders: #d1d5db;
  --tw-prose-td-borders: #e5e7eb;
  --tw-prose-invert-body: #d1d5db;
  --tw-prose-invert-headings: #fff;
  --tw-prose-invert-lead: #9ca3af;
  --tw-prose-invert-links: #fff;
  --tw-prose-invert-bold: #fff;
  --tw-prose-invert-counters: #9ca3af;
  --tw-prose-invert-bullets: #4b5563;
  --tw-prose-invert-hr: #374151;
  --tw-prose-invert-quotes: #f3f4f6;
  --tw-prose-invert-quote-borders: #374151;
  --tw-prose-invert-captions: #9ca3af;
  --tw-prose-invert-code: #fff;
  --tw-prose-invert-pre-code: #d1d5db;
  --tw-prose-invert-pre-bg: rgb(0 0 0 / 50%);
  --tw-prose-invert-th-borders: #4b5563;
  --tw-prose-invert-td-borders: #374151;

  font-size: 1rem;
  line-height: 1.75;
}

.tw-prose :where(p):not(:where([class~="not-prose"] *)) {
  margin-top: 1.25em;
  margin-bottom: 1.25em;
}

.tw-prose :where(img):not(:where([class~="not-prose"] *)) {
  margin-top: 2em;
  margin-bottom: 2em;
}

.tw-prose :where(video):not(:where([class~="not-prose"] *)) {
  margin-top: 2em;
  margin-bottom: 2em;
}

.tw-prose :where(figure):not(:where([class~="not-prose"] *)) {
  margin-top: 2em;
  margin-bottom: 2em;
}

.tw-prose :where(h2 code):not(:where([class~="not-prose"] *)) {
  font-size: 0.875em;
}

.tw-prose :where(h3 code):not(:where([class~="not-prose"] *)) {
  font-size: 0.9em;
}

.tw-prose :where(li):not(:where([class~="not-prose"] *)) {
  margin-top: 0.5em;
  margin-bottom: 0.5em;
}

.tw-prose :where(ol > li):not(:where([class~="not-prose"] *)) {
  padding-left: 0.375em;
}

.tw-prose :where(ul > li):not(:where([class~="not-prose"] *)) {
  padding-left: 0.375em;
}

.tw-prose > :where(ul > li p):not(:where([class~="not-prose"] *)) {
  margin-top: 0.75em;
  margin-bottom: 0.75em;
}

.tw-prose > :where(ul > li > *:first-child):not(:where([class~="not-prose"] *)) {
  margin-top: 1.25em;
}

.tw-prose > :where(ul > li > *:last-child):not(:where([class~="not-prose"] *)) {
  margin-bottom: 1.25em;
}

.tw-prose > :where(ol > li > *:first-child):not(:where([class~="not-prose"] *)) {
  margin-top: 1.25em;
}

.tw-prose > :where(ol > li > *:last-child):not(:where([class~="not-prose"] *)) {
  margin-bottom: 1.25em;
}

.tw-prose :where(ul ul, ul ol, ol ul, ol ol):not(:where([class~="not-prose"] *)) {
  margin-top: 0.75em;
  margin-bottom: 0.75em;
}

.tw-prose :where(hr + *):not(:where([class~="not-prose"] *)) {
  margin-top: 0;
}

.tw-prose :where(h2 + *):not(:where([class~="not-prose"] *)) {
  margin-top: 0;
}

.tw-prose :where(h3 + *):not(:where([class~="not-prose"] *)) {
  margin-top: 0;
}

.tw-prose :where(h4 + *):not(:where([class~="not-prose"] *)) {
  margin-top: 0;
}

.tw-prose :where(thead th:first-child):not(:where([class~="not-prose"] *)) {
  padding-left: 0;
}

.tw-prose :where(thead th:last-child):not(:where([class~="not-prose"] *)) {
  padding-right: 0;
}

.tw-prose :where(tbody td:first-child):not(:where([class~="not-prose"] *)) {
  padding-left: 0;
}

.tw-prose :where(tbody td:last-child):not(:where([class~="not-prose"] *)) {
  padding-right: 0;
}

.tw-prose > :where(:first-child):not(:where([class~="not-prose"] *)) {
  margin-top: 0;
}

.tw-prose > :where(:last-child):not(:where([class~="not-prose"] *)) {
  margin-bottom: 0;
}

.tw-prose-slate {
  --tw-prose-body: #334155;
  --tw-prose-headings: #0f172a;
  --tw-prose-lead: #475569;
  --tw-prose-links: #0f172a;
  --tw-prose-bold: #0f172a;
  --tw-prose-counters: #64748b;
  --tw-prose-bullets: #cbd5e1;
  --tw-prose-hr: #e2e8f0;
  --tw-prose-quotes: #0f172a;
  --tw-prose-quote-borders: #e2e8f0;
  --tw-prose-captions: #64748b;
  --tw-prose-code: #0f172a;
  --tw-prose-pre-code: #e2e8f0;
  --tw-prose-pre-bg: #1e293b;
  --tw-prose-th-borders: #cbd5e1;
  --tw-prose-td-borders: #e2e8f0;
  --tw-prose-invert-body: #cbd5e1;
  --tw-prose-invert-headings: #fff;
  --tw-prose-invert-lead: #94a3b8;
  --tw-prose-invert-links: #fff;
  --tw-prose-invert-bold: #fff;
  --tw-prose-invert-counters: #94a3b8;
  --tw-prose-invert-bullets: #475569;
  --tw-prose-invert-hr: #334155;
  --tw-prose-invert-quotes: #f1f5f9;
  --tw-prose-invert-quote-borders: #334155;
  --tw-prose-invert-captions: #94a3b8;
  --tw-prose-invert-code: #fff;
  --tw-prose-invert-pre-code: #cbd5e1;
  --tw-prose-invert-pre-bg: rgb(0 0 0 / 50%);
  --tw-prose-invert-th-borders: #475569;
  --tw-prose-invert-td-borders: #334155;
}

@media (min-width: 1024px) {
  .lg\:tw-prose-lg {
    font-size: 1.125rem;
    line-height: 1.7777778;
  }

  .lg\:tw-prose-lg :where(p):not(:where([class~="not-prose"] *)) {
    margin-top: 1.3333333em;
    margin-bottom: 1.3333333em;
  }

  .lg\:tw-prose-lg :where([class~="lead"]):not(:where([class~="not-prose"] *)) {
    font-size: 1.2222222em;
    line-height: 1.4545455;
    margin-top: 1.0909091em;
    margin-bottom: 1.0909091em;
  }

  .lg\:tw-prose-lg :where(blockquote):not(:where([class~="not-prose"] *)) {
    margin-top: 1.6666667em;
    margin-bottom: 1.6666667em;
    padding-left: 1em;
  }

  .lg\:tw-prose-lg :where(h1):not(:where([class~="not-prose"] *)) {
    font-size: 2.6666667em;
    margin-top: 0;
    margin-bottom: 0.8333333em;
    line-height: 1;
  }

  .lg\:tw-prose-lg :where(h2):not(:where([class~="not-prose"] *)) {
    font-size: 1.6666667em;
    margin-top: 1.8666667em;
    margin-bottom: 1.0666667em;
    line-height: 1.3333333;
  }

  .lg\:tw-prose-lg :where(h3):not(:where([class~="not-prose"] *)) {
    font-size: 1.3333333em;
    margin-top: 1.6666667em;
    margin-bottom: 0.6666667em;
    line-height: 1.5;
  }

  .lg\:tw-prose-lg :where(h4):not(:where([class~="not-prose"] *)) {
    margin-top: 1.7777778em;
    margin-bottom: 0.4444444em;
    line-height: 1.5555556;
  }

  .lg\:tw-prose-lg :where(img):not(:where([class~="not-prose"] *)) {
    margin-top: 1.7777778em;
    margin-bottom: 1.7777778em;
  }

  .lg\:tw-prose-lg :where(video):not(:where([class~="not-prose"] *)) {
    margin-top: 1.7777778em;
    margin-bottom: 1.7777778em;
  }

  .lg\:tw-prose-lg :where(figure):not(:where([class~="not-prose"] *)) {
    margin-top: 1.7777778em;
    margin-bottom: 1.7777778em;
  }

  .lg\:tw-prose-lg :where(figure > *):not(:where([class~="not-prose"] *)) {
    margin-top: 0;
    margin-bottom: 0;
  }

  .lg\:tw-prose-lg :where(figcaption):not(:where([class~="not-prose"] *)) {
    font-size: 0.8888889em;
    line-height: 1.5;
    margin-top: 1em;
  }

  .lg\:tw-prose-lg :where(code):not(:where([class~="not-prose"] *)) {
    font-size: 0.8888889em;
  }

  .lg\:tw-prose-lg :where(h2 code):not(:where([class~="not-prose"] *)) {
    font-size: 0.8666667em;
  }

  .lg\:tw-prose-lg :where(h3 code):not(:where([class~="not-prose"] *)) {
    font-size: 0.875em;
  }

  .lg\:tw-prose-lg :where(pre):not(:where([class~="not-prose"] *)) {
    font-size: 0.8888889em;
    line-height: 1.75;
    margin-top: 2em;
    margin-bottom: 2em;
    border-radius: 0.375rem;
    padding-top: 1em;
    padding-right: 1.5em;
    padding-bottom: 1em;
    padding-left: 1.5em;
  }

  .lg\:tw-prose-lg :where(ol):not(:where([class~="not-prose"] *)) {
    padding-left: 1.5555556em;
  }

  .lg\:tw-prose-lg :where(ul):not(:where([class~="not-prose"] *)) {
    padding-left: 1.5555556em;
  }

  .lg\:tw-prose-lg :where(li):not(:where([class~="not-prose"] *)) {
    margin-top: 0.6666667em;
    margin-bottom: 0.6666667em;
  }

  .lg\:tw-prose-lg :where(ol > li):not(:where([class~="not-prose"] *)) {
    padding-left: 0.4444444em;
  }

  .lg\:tw-prose-lg :where(ul > li):not(:where([class~="not-prose"] *)) {
    padding-left: 0.4444444em;
  }

  .lg\:tw-prose-lg > :where(ul > li p):not(:where([class~="not-prose"] *)) {
    margin-top: 0.8888889em;
    margin-bottom: 0.8888889em;
  }

  .lg\:tw-prose-lg > :where(ul > li > *:first-child):not(:where([class~="not-prose"] *)) {
    margin-top: 1.3333333em;
  }

  .lg\:tw-prose-lg > :where(ul > li > *:last-child):not(:where([class~="not-prose"] *)) {
    margin-bottom: 1.3333333em;
  }

  .lg\:tw-prose-lg > :where(ol > li > *:first-child):not(:where([class~="not-prose"] *)) {
    margin-top: 1.3333333em;
  }

  .lg\:tw-prose-lg > :where(ol > li > *:last-child):not(:where([class~="not-prose"] *)) {
    margin-bottom: 1.3333333em;
  }

  .lg\:tw-prose-lg :where(ul ul, ul ol, ol ul, ol ol):not(:where([class~="not-prose"] *)) {
    margin-top: 0.8888889em;
    margin-bottom: 0.8888889em;
  }

  .lg\:tw-prose-lg :where(hr):not(:where([class~="not-prose"] *)) {
    margin-top: 3.1111111em;
    margin-bottom: 3.1111111em;
  }

  .lg\:tw-prose-lg :where(hr + *):not(:where([class~="not-prose"] *)) {
    margin-top: 0;
  }

  .lg\:tw-prose-lg :where(h2 + *):not(:where([class~="not-prose"] *)) {
    margin-top: 0;
  }

  .lg\:tw-prose-lg :where(h3 + *):not(:where([class~="not-prose"] *)) {
    margin-top: 0;
  }

  .lg\:tw-prose-lg :where(h4 + *):not(:where([class~="not-prose"] *)) {
    margin-top: 0;
  }

  .lg\:tw-prose-lg :where(table):not(:where([class~="not-prose"] *)) {
    font-size: 0.8888889em;
    line-height: 1.5;
  }

  .lg\:tw-prose-lg :where(thead th):not(:where([class~="not-prose"] *)) {
    padding-right: 0.75em;
    padding-bottom: 0.75em;
    padding-left: 0.75em;
  }

  .lg\:tw-prose-lg :where(thead th:first-child):not(:where([class~="not-prose"] *)) {
    padding-left: 0;
  }

  .lg\:tw-prose-lg :where(thead th:last-child):not(:where([class~="not-prose"] *)) {
    padding-right: 0;
  }

  .lg\:tw-prose-lg :where(tbody td):not(:where([class~="not-prose"] *)) {
    padding-top: 0.75em;
    padding-right: 0.75em;
    padding-bottom: 0.75em;
    padding-left: 0.75em;
  }

  .lg\:tw-prose-lg :where(tbody td:first-child):not(:where([class~="not-prose"] *)) {
    padding-left: 0;
  }

  .lg\:tw-prose-lg :where(tbody td:last-child):not(:where([class~="not-prose"] *)) {
    padding-right: 0;
  }

  .lg\:tw-prose-lg > :where(:first-child):not(:where([class~="not-prose"] *)) {
    margin-top: 0;
  }

  .lg\:tw-prose-lg > :where(:last-child):not(:where([class~="not-prose"] *)) {
    margin-bottom: 0;
  }
}
