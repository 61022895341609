.sitzkrieger__mediathek__search {
  width: 100%;
  display: flex;
  -moz-box-align: center;
  align-items: center;
  position: relative;
  transition: all 0.2s ease 0s;
  outline: currentcolor none medium;
  appearance: none;
  font-size: 16px;
  padding-left: 2.5rem;
  padding-right: 1rem;
  height: 2.5rem;
  border-radius: 9999px;
  border-style: solid;
  border-width: 1px;
  border-image: none 100% / 1 / 0 stretch;
  border-color: inherit;
  background-color: rgb(255, 255, 255);
  box-shadow: rgba(0, 0, 0, 0.2) 0 6px 6px -1px, rgba(0, 0, 0, 0.06) 0 2px 4px -1px;

  &:focus {
    box-shadow: rgba(0, 0, 0, 0.1) 0 4px 4px -1px, rgba(0, 0, 0, 0.06) 0 1px 4px -1px;
  }

  &__tag__input {
    clip: rect(0, 0, 0, 0)
  }
}

.BrainhubCarouselItem {
  align-items: initial !important;
}

.slick-slider {
  .slick-track {
    display: flex !important;
    min-width: 100vw !important;

    .slick-slide {
      height: inherit;
      display: flex !important;
      justify-content: center;

      > div {
        display: flex !important;
      }
    }
  }

  .slick-prev {
    left: -30px;
  }

  .slick-prev::before,
  .slick-next::before {
    color: rgb(0, 83, 126);
    opacity: 1;
    font-size: 30px;
  }
}
