.sitzkrieger__prices__title {
  top: 25%;
  background: rgba(255, 255, 255, 70%);
  padding: 20px;
  font-weight: 800;
}

.sitzkrieger__prices__price {
  background: rgba(255, 255, 255, 70%);
  padding: 10px;
  min-height: 100px;
}

.sitzkrieger__prices__img {
  margin-top: -30%;
  max-height: 350px;
  margin-left: auto;
  margin-right: auto;
}

@media screen and (min-width: 768px) {
  .sitzkrieger__prices__img {
    margin-top: -50%;
    max-height: 450px;
  }
}

.sitzkrieger-slider {
  width: 100%;
}

.sitzkrieger-slider__thumb {
  cursor: pointer;
  position: absolute;
  z-index: 100;
  background: #cb100a;
  border: 5px solid #cb100a;
  border-radius: 100%;
  display: block;
  box-shadow: 0 0 2px 0 rgba(0, 0, 0, 44%);
}

.sitzkrieger-slider__thumb.active {
  background-color: #f93f43;
}

.sitzkrieger-slider__track {
  position: relative;
  background: #ddd;
}

.sitzkrieger-slider__track.sitzkrieger-slider__track-0 {
  background: #54595f;
}

.sitzkrieger-slider .sitzkrieger-slider__track {
  top: 20px;
  height: 4px;
}

.sitzkrieger-slider .sitzkrieger-slider__thumb {
  top: 12px;
  width: 20px;
  outline: none;
  height: 20px;
  line-height: 38px;
}
